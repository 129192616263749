:root {
    --sidebar-width: 250px;
    --header-height: 50px;
    --sidebar-hidden-transition-time: 0.4s;
    --larger-font-size: 1rem;
    --base-font-size: 0.875rem;
    --compact-font-size: 0.75rem;
    --base-text-color: #000;
    --sidebar-text-color: #555;
    --dark-mode-text-color: var(--bs-light);
    --dark-mode-sidebar-text-color: #dddddd;
    --base-gridline-color: rgba(0, 0, 0, 0.1);
    --dark-mode-gridline-color: rgba(255, 255, 255, 0.1);

    /*I guess bootstrap doesn't package these anymore after migrating from v4 to v5*/
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1400px;

    /* Override react-toastify defaults */
    --toastify-color-success: var(--bs-success);
    --toastify-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    --toastify-toast-min-height: 32px;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: var(--base-font-size);
    font-weight: 400;
    line-height: 1.5;
    color: var(--base-text-color);
    text-align: left;
    background-color: white;
}
body.dark-mode{
    color: var(--dark-mode-text-color);
    background-color: #1e1e1e;
}

nav ul,
nav ol {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

/* Override Bootstrap Defaults*/
a {
    text-decoration: none;
}

/* SIDEBAR CLASSES */
.sidebar {
    width: var(--sidebar-width);
    position: fixed;
    left: 0;
    bottom: 0;
    border-right: 1px solid lightgray;
    background: #ececec;
    z-index: 1010;
    transition: var(--sidebar-hidden-transition-time);
}

.page-header-fixed .sidebar {
    top: var(--header-height);
}

.page-sidebar-hidden .sidebar {
    left: calc(var(--sidebar-width) * -1);
    transition: var(--sidebar-hidden-transition-time);
}

.sidebar-header {
    margin: 0;
    padding: 15px 20px 3px;
    line-height: 20px;
    font-size: 0.75rem;
    color: gray;
    font-weight: 600;
}

.sidebar-item {
    padding: 3px 20px;
    margin: 1px 12px;
    border-radius: 5px;
    color: var(--sidebar-text-color);
    display: block;
}

.sidebar-item i {
    color: var(--bs-primary);
    display: inline-block;
    width: 25px;
}

.sidebar-item:hover {
    color: var(--sidebar-text-color);
    background-color: lightgray;
    text-decoration: none;
}

.sidebar-item.active {
    background-color: var(--bs-primary);
    color: white;
}

.sidebar-item.active i {
    color: white;
}

.dark-mode .sidebar {
    background: #282828;
    border-color: black;
}

.dark-mode .sidebar-item {
    color: var(--dark-mode-sidebar-text-color);
}

.dark-mode .sidebar-item:hover {
    color: var(--dark-mode-sidebar-text-color);
    background-color: #383838;
}

.dark-mode .sidebar-item.active,
.dark-mode .sidebar-item.active i {
    color: white;
    background-color: var(--bs-primary);
}


/* PAGE HEADER CLASSES */
.header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1020;
    margin-bottom: 0;
    padding: 0;
    background-color: white;
    border-bottom: 1px solid #f1f1f1;
    height: var(--header-height);
    position: fixed;
}

.header-logo {
    padding: 10px 20px;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #000;
}

.header-logo b {
    font-weight: 700;
}

.header-logo:focus,
.header-logo:hover {
    text-decoration: none;
    color: #000;
}

@media (max-width: 575.98px) {
    .ledgers-dropdown-text{
        display: inline-block;
        max-width: 125px;
    }
}

.ledgers-dropdown-toggle {
    max-width: calc(100vw - 275px)
}

.dark-mode .header {
    background-color: #181818;
    border-color: #232323;
}

.dark-mode .header-logo {
    color: white;
}

.dark-mode .header-logo:focus,
.dark-mode .header-logo:hover {
    color: white;
}

/* CONTENT CLASSES */
.content {
    position: relative;
    left: var(--sidebar-width);
    max-width: calc(99vw - var(--sidebar-width));
    padding: 20px 30px;
    transition: var(--sidebar-hidden-transition-time);
}

@media (max-width: 767.98px) {
    .content{
        left: 0;
        max-width: 100vw;
    }
}

.page-header-fixed .content {
    top: var(--header-height);
}
.page-without-sidebar .content {
    left: 0;
}
.page-sidebar-hidden .content {
    left: 0;
    max-width: 100vw;
    transition: var(--sidebar-hidden-transition-time);
}

/* MODIFICATIONS TO BOOTSTRAP CLASSES FOR DARK MODE */
.dark-mode .card {
    background-color: #181818;
}

.dark-mode .bg-light {
    background-color: #202020 !important;
}

.dark-mode .bg-light.card{
    background-color: #181818 !important;
}

.dark-mode .border,
.dark-mode .border-top,
.dark-mode .border-bottom,
.dark-mode .border-left,
.dark-mode .border-right {
    border-color: #383838 !important;
}

/* MODIFICATIONS AND ADDITIONS TO BOOTSTRAP BUTTONS */ /* TODO: darkmode */
.btn {
    font-size: var(--base-font-size);
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid rgba(27, 31, 35, 0.15);
}
.btn-white {
    background-color: #fff;
    border-color: #c8c8c8;
}
.btn-white:hover {
    background-color: #e1e1e1;
    border-color: #c8c8c8;
}
.btn-default {
    background-color: #fff;
    border-color: #d5d5d5;
}
.btn-default:hover {
    background-color: #eee;
    border-color: #d5d5d5;
}

.dark-mode .btn-white,
.dark-mode .btn-light {
    background-color: #181818;
    border-color: #232323;
    color: var(--dark-mode-text-color)
}

.dark-mode .modal-content .btn-white,
.dark-mode .modal-content .btn-light {
    background-color: #1e1e1e;
    border-color: #383838;
    color: var(--dark-mode-text-color)
}

.dark-mode .btn-white:hover,
.dark-mode .btn-light:hover,
.dark-mode .btn-light:active {
    background-color: #2a2a2a !important;
    border-color: #232323 !important;
    color: var(--dark-mode-text-color) !important;
}

.nested-btn,
.dark-mode .nested-btn {
    background-color: inherit
}
.nested-btn.btn-white:hover {
    background-color: #fff
}
.dark-mode .nested-btn.btn-white:hover{
    background-color: #181818;
}

/* MODIFICATIONS TO BOOTSTRAP FORMS
 * Remember to update styled-select.js when updating this
*/
.form-control, .input-group-text {
    font-size: var(--base-font-size);
}

.form-control:disabled,
.form-control.discouraged {
    color: hsl(0,0%,60%);
    background-color: hsl(0,0%,95%);
    border-color: hsl(0,0%,90%);    
}

.form-label.disabled,
.form-label.discouraged,
.col-form-label.disabled,
.col-form-label.discouraged,
.form-check-label.disabled,
.form-check-label.discouraged {
    opacity: 0.5;
}

.dark-mode .form-control, .dark-mode .input-group-text {
    background-color: #191919;
    border-color: #373737;
    color: var(--dark-mode-text-color);
}

.dark-mode .form-control:disabled,
.dark-mode .form-control.discouraged {
    background-color: #373737;
    color: hsl(0,0%,50%);
}

/* MODIFICATIONS TO BOOTSTRAP DROPDOWN*/

.dropdown-divider {
    border-top: 1px solid #e9ecef;
}

.dropdown-item,
.dropdown-item-text {
    font-size: var(--base-font-size);
}

.dark-mode .dropdown-menu {
    background-color: #282828;
    color: var(--dark-mode-text-color);
}

.dark-mode .dropdown-item-text,
.dark-mode .dropdown-item {
    color: var(--dark-mode-text-color);
}

.dropdown-item:hover {
    background-color: var(--bs-primary);
    color: white;
}



/* MODIFICATIONS TO BOOTSTRAP MODAL */
.modal-content {
    border-radius: 10px;
}
.dark-mode .modal-content {
    background-color: #1e1e1e;
}
.dark-mode .modal-footer,
.dark-mode .modal-header {
    border-color: #383838;
}

/* MODIFICATIONS TO BOOTSTRAP PILLS */
a.nav-link {
    color: #6f6f6f;
    font-weight: 500;
    font-size: var(--base-font-size);
}
a.nav-link:hover {
    text-decoration: none;
    color: var(--base-text-color);
}
.dark-mode a.nav-link:hover {
    color: var(--dark-mode-text-color);
}

/* MODIFICATIONS TO BOOTSTRAP TABLE */
.table {
    margin-bottom: 0;
    color: inherit;
}

/* Hide arrows for input type=number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}


/* MODIFICATIONS TO BOOTSTRAP ALERT*/
.alert {
    border-radius: 10px;
}

/* MODIFICATIONS TO SWEETALERT */
.dark-mode .sweet-alert {
    background-color: #1e1e1e !important;
}

/* PSEUDO-TABLE UTILITIES */
.pseudo-table {
    border-collapse: collapse !important;
}

.pseudo-th {
    font-weight: 600;
    padding: .75rem;
}
.pseudo-td {
    padding: .75rem;
}
.pseudo-tr {
    border-top: #dadada 1px solid;
}
.dark-mode .pseudo-tr{
    border-top: #383838 1px solid;
}
.pseudo-tr:first-child {
    border-top: none;
}
.dark-mode .pseudo-tr:first-child{
    border-top: none;
}

.pseudo-tfoot{
    border-top: silver 1px solid;
    font-weight: 600;
}
.dark-mode .pseudo-tfoot {
    border-top: #484848 1px solid /*TODO check if this actually looks ok*/
}

a.pseudo-tr,
.clickable.pseudo-tr {
    color: var(--base-text-color);
}
a.pseudo-tr:hover,
.clickable.pseudo-tr:hover {
    text-decoration: none;
    color: var(--base-text-color);
    background-color: #e1e1e1;
}
.dark-mode a.pseudo-tr,
.dark-mode .clickable.pseudo-tr {
    color: var(--dark-mode-text-color);
}
.dark-mode a.pseudo-tr:hover,
.dark-mode .clickable.pseudo-tr:hover {
    color: var(--dark-mode-text-color);
    background-color: #2a2a2a;
}

.striped-row {
    display: flex;
    padding: .75rem;
    border-radius: 5px;
}
.striped-row:nth-child(odd) {
    background-color: var(--bs-light);
}

.striped-row:nth-child(even) {
    background-color: white;
}

.dark-mode .striped-row:nth-child(odd) {
    background-color: #222222;
}

.dark-mode .striped-row:nth-child(even) {
    background-color: #1e1e1e;
}

.report-row-detailed { /*deprecate these*/
    background-color: #ececec;
}
.dark-mode .report-row-detailed {
    background-color: #151515;
}

.report-header {
    background-color: #d4d4d4;
    font-weight: 600;
}
.dark-mode .report-header {
    background-color: #404040;
    font-weight: 600;
}
.report-subheader {
    background-color: #ececec;
    font-weight: 600;
}
.dark-mode .report-subheader {
    background-color: #282828;
    font-weight: 600;
}

/* LOGIN AND REGISTRATION PAGES */
.login-page {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;   
    background-size: cover;
    background-repeat: no-repeat;
}
.login-left {
    width: 100%;
}
.login-right {
    min-width: 500px;
    width: 500px;
    padding: 60px;
    height: 100%;
    background-color: white;
    z-index: 1;
}
.dark-mode .login-right {
    background-color: #1e1e1e;
}
@media (max-width: 1199.98px) {
    .login-right {
        min-width: 400px;
        width: 400px;
        padding: 45px;
    }
}
@media (max-width: 767.98px) {
    .login-left {
        display: none;
    }
    .login-right {
        width: 100%;
    }
}

@media (max-width: 575.98px) {
    .login-right {
        padding: 30px;
    }
}

.login-left-caption {
    color: rgba(255, 255, 255, 0.85);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 60px 60px 30px;
    font-size: 18px;
    letter-spacing: 0.25px;
    font-weight: 300;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000 100%);
    z-index: 0;
}
.login-left-caption-title {
    font-weight: 300;
    color: #fff;
    font-size: 36px;
}
.login-left-caption-title b {
    font-weight: 700;
}
@media (max-width: 1199.98px) {
    .login-left-caption {
        padding: 45px 45px 30px;
        font-size: 16px;
    }
    .login-left-caption-title {
        font-size: 28px;
    }
}

.login-header {
    padding-top: 30px;
    font-size: 28px;
    color: black;
    font-weight: 300;
}

.dark-mode .login-header {
    color: white;
}

.login-header b {
    font-weight: 700;
}
@media (max-width: 767.98px) {
    .login-header {
        font-size: 20px;
    }
}

/* Account Transaction Report custom height for report module */
.account-transactions-report-module {
    max-height: calc(100vh - 470px);
    min-height: 250px;
    overflow-x: auto;
    overflow-y: auto;
}

/* Utility classes, mostly left from adjusting color admin */
.ms-n1 {
    margin-left: -0.25rem !important;
}
.ms-n2 {
    margin-left: -0.5rem !important;
}
.ms-01 {
    margin-left: .125rem !important;
}
.clickable:hover {
    cursor: pointer;
}

.dark-mode hr {
    color: var(--dark-mode-text-color);
    border-color: var(--dark-mode-gridline-color);
}

.very-rounded {
    border-radius: 10px;
}

.border-inherit {
    border: inherit;
}

.font-size-larger {
    font-size: var(--larger-font-size);
}

.font-size-standard {
    font-size: var(--base-font-size);
}

.font-size-compact {
    font-size: var(--compact-font-size);
}

.indent, .indent-1 {
    padding-left: 2em;
}
.indent-2 {
    padding-left: 4em;
}
.indent-3 {
    padding-left: 6em;
}
.indent-4 {
    padding-left: 8em;
}

.vertical-line {
    border-right: 5px solid;
    border-color: rgba(52, 143, 226, .2);
    align-self: stretch;
}

.cursor-pointer {
    cursor: pointer;
}

.width-125 {
    width: 125px !important;
}

.width-150 {
    width: 150px !important;
}

.width-175 {
    width: 175px !important;
}

.width-200 {
    width: 200px ;
}

.width-10ch {
    width: 10ch !important;
}

.min-width-xs{
    min-width: calc(var(--breakpoint-xs) - 60px);
}

.min-width-sm{
    min-width: calc(var(--breakpoint-sm) - 60px);
}

.min-width-md{
    min-width: calc(var(--breakpoint-md) - 60px);
}

.min-width-lg{
    min-width: calc(var(--breakpoint-lg) - 60px);
}

.min-width-xl{
    min-width: calc(var(--breakpoint-xl) - 60px);
}

.min-width-xxl{
    min-width: calc(var(--breakpoint-xxl) - 60px);
}

.overflow-visible {
    overflow: visible;
}

.font-weight-600, .font-weight-semibold, .fw-600, .fw-semibold {
    font-weight: 600 !important;
}

.bg-white-hover {
    background-color: #f3f3f3 !important;
}

.bg-light-hover {
    background-color: #e0e0e0 !important;
}

.rotating-caret {
    opacity: 0.5;
    transition: all 0.2s linear;
}
.rotating-caret.expand{
    opacity: 1;
    transform: rotate(90deg);
}

@keyframes slide-in {
    from {
        left: 100%;
    }
    to {
        left: 0%;
    }
}
.slide-in {
    animation-name: "slide-in";
    animation-duration: 0.15s;
    position: relative;
}
